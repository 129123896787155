import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userLogout } from "../../actions/authAction";
import { useDispatch } from "react-redux";


const Sidebar = (props) => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState("");
    // function for logout
    const handleLogout = () => {
        dispatch(userLogout());
    };

    // get logged in user data from localstorage
    useEffect(()=>{
        setUserData(JSON.parse(localStorage.getItem("userData")));
    },[])
    return (
        <>
            <div className="left-nav">
                <span className="mene-tg"><i className="fas fa-bars"></i></span>
                <div className="l-logo">
                    <Link to="/dashboard"><img src="/img/logo.png" alt=""/></Link>
                </div>
                
                <div className="l-nav-bar">
                    <ul>
                        <li className=" active"><Link to="/dashboard" ><img src="../img/home-24.png" alt=""/> Dashboard</Link></li>
                        <li><Link className="nav-link"><img src="../img/left-nav-i1.png" alt=""/> Master</Link>
                            <ul className="sub-nav">
                                <li><Link to="/buyers">Buyer</Link></li>
                                <li><Link to="/sellers">Seller</Link></li>
                                <li><Link to="/items">Item</Link></li>
                            </ul>
                        </li>
                        <li><Link className="nav-link"><img src="../img/left-nav-i2.png" alt=""/> Transaction</Link>
                            <ul className="sub-nav">
                                <li><Link to="/orders">Sales Order</Link></li>
                                <li><Link to="/invoices">Invoice</Link></li>
                            </ul>
                        </li>
                        {(userData?.role !== 2) ? <>
                            <li><Link className="nav-link"><img src="../img/left-nav-i3.png" alt=""/> Report</Link>
                                <ul className="sub-nav">
                                    <li><Link to={"/report/order-status-report"}>Order Status Report</Link></li>
                                    <li><Link to={"/report/total-purchase-report"}>Total Purchase Report</Link></li>
                                    <li><Link to={"/report/commission-report"}>Commission Report</Link></li>
                                    <li><Link to={"/report/total-sales-report"}>Total Sales Report</Link></li>
                                </ul>
                            </li>
                        </> :''}
                        <li><Link className="nav-link"><img src="../img/left-nav-i4.png" alt=""/> Utilities</Link>
                            <ul className="sub-nav">
                                {(userData?.role === 1) ? <li><Link to="/users">Users</Link></li> : ''}
                                {/* {(userData?.role === 1) ? <li><Link to="/user/search-engine">Search Engine</Link></li> : '' } */}
                                <li><Link to="/user/set-financial-year">Set Financial Year</Link></li>
                                {(userData?.role === 1) ? <li><Link to="/user/recycle-bin">Recycle Bin</Link></li> : '' }
                                {(userData?.role === 1) ? <li><Link to="/user/system-setting">System Settings</Link></li> : '' }
                            </ul>
                        </li>
                        <li><Link onClick={handleLogout}><img src="../img/left-nav-i5.png" alt=""/> Logout</Link></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Sidebar;